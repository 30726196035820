import React from "react";
import "./VerticalSlider.css";

const VerticalSlider = ({ images, handleSelectImage }) => {
  const baseUrl = process.env.REACT_APP_IMAGE_BASE_URL; // Get the base URL from env

  return (
    <div className="vertical-slider-container">
      <div className="vertical-slider">
        {images.map((image, index) => (
          <div
            key={index}
            className="vertical-slider-item"
            onClick={() => handleSelectImage(image)}
          >
            <img src={`${image}`} alt={`Product thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalSlider;
