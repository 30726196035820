import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ThemeProvider } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../redux/Apis/Auth";
import Loader from "../Loader/Loader";
import { StandardInputTheme } from "../Auth/StandardInputTheme";
import "./Form.css";
import { useTranslation } from "react-i18next";
const LoginForm = () => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const [login, { isSuccess, isLoading, error }] = useLoginMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Otp sent. Please check your email");
      const email = watch("email");
      navigate(`/auth/verify-code?email=${encodeURIComponent(email)}`);
    } else if (error && error.status === 403) {
      toast.error("Email not verified");
      navigate("/verify-email");
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    login(data);
  };

  return (
    <ThemeProvider theme={StandardInputTheme}>
      {isLoading && <Loader />}
      <div className="login-form-container shadow">
        <form>
          <h2 className="loginpage_heading">{t("Login")}</h2>
          <div className="input-group">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  id="standard-basic"
                  label="Email Address"
                  variant="standard"
                  {...field}
                />
              )}
            />
          </div>
          <div className="input-group">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    {...field}
                  />
                </FormControl>
              )}
            />
          </div>
          <div className="login-flex">
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="remember-me"
                className="me-2 checkbox-input"
              />
              <label htmlFor="remember-me" className="checkbox-custom">
                {t("RemBe")}
              </label>
            </div>
            <Link className="signin_link" to="/forgot-password">
              {t("Forgot Password")}
            </Link>
          </div>
          <hr className="my-3" />
          {/*<div className="links">*/}
          {/*    <button*/}
          {/*        className="btn"*/}
          {/*        type="button"*/}
          {/*        onClick={() => navigate('/auth/register')}>*/}
          {/*        CREATE ACCOUNT*/}
          {/*    </button>*/}
          {/*</div>*/}
          <p>
            {t("Don't have an account?")}{" "}
            <Link className={`signin_link`} to={`/auth/register`}>
              {t("Register")}
            </Link>
            <button
              type="submit"
              className="btn btn-login"
              onClick={handleSubmit(onSubmit)}
            >
              {t("Login")}
            </button>
          </p>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default LoginForm;
