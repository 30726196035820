import {
  Modal,
  Box,
  TextField,
  Button,
  Rating,
  Typography,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

const ReviewModal = ({ open, handleClose, product, fetchProductDetails }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reviewData, setReviewData] = useState({
    name: "",
    email: "",
    rating: 0,
    comment: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReviewData({ ...reviewData, [name]: value });
  };

  const handleRatingChange = (event, newValue) => {
    setReviewData({ ...reviewData, rating: newValue });
  };

  const handleReviewSubmit = async () => {
    setIsLoading(true);
    const payload = {
      ...reviewData,
      product: product,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL_NEW}/public/products/${product}/reviews/`,
      payload
    );
    if (response.status === 201) {
      await fetchProductDetails();
      handleClose();
    } else {
      console.error("Error submitting review:", response.data);
    }
    setIsLoading(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Write a Review
        </Typography>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          name="name"
          value={reviewData.name}
          onChange={handleInputChange}
        />
        <TextField
          label="Email"
          fullWidth
          margin="normal"
          name="email"
          value={reviewData.email}
          onChange={handleInputChange}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 2,
            marginTop: 2,
          }}
        >
          <Typography>Rating:</Typography>
          <Rating
            name="rating"
            value={reviewData.rating}
            onChange={handleRatingChange}
            precision={1}
          />
        </Box>
        <TextField
          label="Review"
          multiline
          rows={4}
          fullWidth
          name="comment"
          value={reviewData.comment}
          onChange={handleInputChange}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 2,
            gap: 1,
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              color: "white",
              backgroundColor: "black",
              border: "1px solid transparent", // Initial transparent border
              "&:hover": {
                color: "black", // Text color changes to black on hover
                backgroundColor: "white", // Background color changes to white on hover
                border: "1px solid black", // Border added on hover
              },
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleReviewSubmit}
            disabled={isLoading}
            sx={{
              color: "white",
              backgroundColor: "black",
              border: "1px solid transparent", // Initial transparent border
              "&:hover": {
                color: "black", // Text color changes to black on hover
                backgroundColor: "white", // Background color changes to white on hover
                border: "1px solid black", // Border added on hover
              },
            }}
          >
            {isLoading ? <CircularProgress color="inherit" /> : "Submit"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReviewModal;
