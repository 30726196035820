import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/general/`

export const generalApiService = createApi({
    reducerPath: 'generalApi',
    baseQuery: fetchBaseQuery({
        baseUrl
    }),
    endpoints: (builder) => ({
        getLOV: builder.query({
            query: () => "lov",
            keepUnusedDataFor: 0
        })
    })
})

export const {
    useGetLOVQuery,
} = generalApiService