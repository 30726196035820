import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = `${process.env.REACT_APP_BASE_URL}/exchange/`;

export const exchangeRateApiService = createApi({
  reducerPath: "exchangeRateApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().authSlice.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLatestExchangeRate: builder.query({
      query: () => "latest", // Assuming the endpoint for the latest exchange rate is "latest"
      keepUnusedDataFor: 0,
    }),
    getAllExchangeRates: builder.query({
      query: () => "", // Assuming the endpoint for all exchange rates is the base URL
      keepUnusedDataFor: 0,
    }),
  }),
});
export const {
  useGetLatestExchangeRateQuery,
  useGetAllExchangeRatesQuery,
} = exchangeRateApiService;
