import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = `${process.env.REACT_APP_BASE_URL_NEW}/auth/`;

export const authApiService = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (payload) => ({
        url: "otp-login/",
        method: "POST",
        body: { ...payload, source: "user", deviceId: "skeleton_device" },
      }),
    }),
    deactivateAccount: builder.mutation({
      query: (userId) => {
        console.log(`Deactivating account for user ID: ${userId}`); // Log the user ID
        return {
          url: `deactivate/${userId}`,
          method: "PUT",
        };
      },
    }),

    forgetPassword: builder.mutation({
      query: (payload) => ({
        url: "forgot-password/",
        method: "POST",
        body: payload,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (payload) => ({
        url: "otp-verify/",
        method: "POST",
        body: payload,
      }),
    }),
    resetPassword: builder.mutation({
      query: (payload) => ({
        url: "reset-password/",
        method: "POST",
        body: payload,
      }),
    }),
    sendVerificationToken: builder.mutation({
      query: (payload) => ({
        url: "generate-user-token",
        method: "POST",
        body: payload,
      }),
    }),
    verifyToken: builder.mutation({
      query: (payload) => ({
        url: "verify-user",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgetPasswordMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useSendVerificationTokenMutation,
  useVerifyTokenMutation,
  useDeactivateAccountMutation,
} = authApiService;
