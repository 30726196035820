import React from "react";
import { Box, Typography, Rating } from "@mui/material";

const ProductDetailReviews = ({ rating, totalReviews }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        marginTop: "16px",
      }}
    >
      {/* Rating Component */}
      <Rating
        value={rating}
        precision={1} // For fractional ratings like 4.5
        readOnly
      />

      {/* Text */}
      <Typography variant="body2" color="textSecondary">
        {rating} out of 5 ({totalReviews} reviews)
      </Typography>
    </Box>
  );
};

export default ProductDetailReviews;
