import { createSlice } from "@reduxjs/toolkit";
import { authApiService } from "../../Apis/Auth";

let initialState = {
  isLoggedIn: false,
  token: null,
  user: null,
  userId: null, // Stores userId separately
};

export const AuthSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
      state.userId = null; // Clear userId on logout
    },
    updateUser: (state, action) => {
      const { first_name, last_name, phone } = action.payload;
      if (state.user) {
        state.user.first_name = first_name;
        state.user.last_name = last_name;
        state.user.phone = phone;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApiService.endpoints.verifyOtp.matchFulfilled,
      (state, action) => {
        let { success, access, user } = action?.payload;
        if (user) {
          state.isLoggedIn = true;
          state.token = access;
          state.user = user;
          state.userId = user?.id;
        } else {
          state.isLoggedIn = false;
          state.token = null;
          state.user = null;
          state.userId = null; // Clear userId if login fails
        }
      }
    );
  },
});

export const { logout, updateUser } = AuthSlice.actions;
export default AuthSlice.reducer;
