import React, { useState, useEffect, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetAllCategoriesWithSubcategoriesQuery } from "../../redux/Apis/Category";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setCategories } from "../../redux/Slices/Category/index";
import { useTranslation } from "react-i18next";
import "./MobileMegaMenu.css";
import axios from "axios";

const MobileMegaMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedLanguage = useSelector((state) => state.language);

  // Redux categories and state
  const storedCategories = useSelector((state) => state.category.categories);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [allSubCategories, setAllSubCategories] = useState([]);

  const {
    data: response,
    error,
    isLoading,
  } = useGetAllCategoriesWithSubcategoriesQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true,
  });

  const categories = useMemo(() => storedCategories, [storedCategories]);

  const fetchSubCategories = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL_NEW}/public/subcategories/`
      );
      setAllSubCategories(res?.data?.data || []);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  useEffect(() => {
    fetchSubCategories();
    if (response?.data) {
      dispatch(setCategories(response.data));
    }
  }, [response, dispatch]);

  const handleCategoryClick = (categoryId, subcategoryId = "") => {
    navigate(
      `/products?category_id=${categoryId}${
        subcategoryId ? `&subcategory_id=${subcategoryId}` : ""
      }`
    );
    setMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div className="mobile-menu-container">
      <li className="menu-item" onClick={toggleMobileMenu}>
        {t("Products")}
      </li>

      <div className={`mobile-menu ${mobileMenuOpen ? "open" : ""}`}>
        <div className="mobile-menu-header">
          <button className="close-button" onClick={toggleMobileMenu}>
            ✕
          </button>
        </div>
        <div className="categories-list">
          {response?.data?.map((category) => (
            <Accordion key={category.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${category.id}-content`}
                id={`panel${category.id}-header`}
              >
                {category?.Image ? (
                  <img
                    src={category?.Image}
                    alt={`${category.name_en} icon`}
                    className="category-icon-nav"
                    style={{ marginRight: selectedLanguage === "ar" ? 0 : 15, marginLeft: selectedLanguage === "ar" ? 15 : 0 }}
                  />
                ) : (
                  <span className="default-icon" style={{ marginRight: 10 }}>
                    🔍
                  </span>
                )}
                <Typography>
                  {selectedLanguage === "en"
                    ? category?.name_en || "Default Name (EN)"
                    : selectedLanguage === "ar"
                    ? category?.name_ar || "Default Name (AR)"
                    : category?.name_ku || "Default Name (KU)"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="subcategory-list">
                  {allSubCategories
                    .filter(
                      (subcategory) =>
                        subcategory.parent_category_id === category.id
                    )
                    .map((subcategory) => (
                      <Typography
                        key={subcategory.id}
                        className="subcategory-item"
                        onClick={() =>
                          handleCategoryClick(category.id, subcategory.id)
                        }
                        style={{ cursor: "pointer", padding: "5px 0" }}
                      >
                        {selectedLanguage === "en"
                          ? subcategory.name_en || "Default Subcategory (EN)"
                          : selectedLanguage === "ar"
                          ? subcategory.name_ar || "Default Subcategory (AR)"
                          : subcategory.name_ku || "Default Subcategory (KU)"}
                      </Typography>
                    ))}
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMegaMenu;
