import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './Navbar.css';

const ModalSearchBox = ({ showSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State to manage the search term
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    if (showSearch) {
      setIsOpen(true);
    }
  }, [showSearch]);

  const handleClose = (event) => {
    event.preventDefault();
    setIsOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      navigate(`/products?search=${searchTerm}`);
      setIsOpen(false); // Navigate to search page with searchTerm
    }
  };

  return (
    <>
      <div id="search-box" className={isOpen ? '-open' : ''}>
        <div className="container">
          <a className="close" href="#close" onClick={handleClose}></a>
          <div className="search-main">
            <div className="search-inner">
              <form>
                <input
                  type="text"
                  id="inputSearch"
                  name="search"
                  autoComplete="off"
                  placeholder="Search ..."
                  value={searchTerm} // Bind the input value to searchTerm
                  onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm on change
                  onKeyDown={handleKeyDown} // Handle key down event
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalSearchBox;
