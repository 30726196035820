import React, { useEffect, useState } from "react";
import "./ProductDetailsImage.css";
import VerticalSlider from "./VerticalSlider";

const ProductDetailsImage = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    if (images && images.length > 0) {
      setCurrentImage(images[0]);
    }
  }, [images]);

  const handleSelectImage = (img) => {
    setCurrentImage(img);
  };

  const handleNext = () => {
    const currentIndex = images.indexOf(currentImage);
    const nextIndex = (currentIndex + 1) % images.length;
    setCurrentImage(images[nextIndex]);
  };

  const handlePrev = () => {
    const currentIndex = images.indexOf(currentImage);
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentImage(images[prevIndex]);
  };

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsZoomed(false);
  };

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <div className="product-details-images-container">
      {images && images.length > 0 ? (
        <>
          <div className="product-details-slider">
            <VerticalSlider
              images={images}
              handleSelectImage={handleSelectImage}
            />
          </div>
          <div className="product-details-image">
            <div
              id="productCarousel"
              className="carousel slide"
              data-ride="carousel"
              data-interval="false"
            >
              <div className="carousel-inner">
                {images.map((img, index) => (
                  <div
                    className={`carousel-item ${
                      currentImage === img ? "active" : ""
                    }`}
                    key={index}
                  >
                    <img
                      className="d-block w-100 h-100 img-fluid"
                      src={`${currentImage}`}
                      alt={`Product ${index + 1}`}
                      onClick={handleImageClick}
                    />
                  </div>
                ))}
              </div>
              <a
                className="carousel-control-prev"
                role="button"
                onClick={handlePrev}
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="carousel-control-next"
                role="button"
                onClick={handleNext}
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>

          {isModalOpen && (
            <div className="modal-overlay" onClick={handleCloseModal}>
              <div className="modal-content">
                <img
                  src={`${currentImage}`}
                  alt="Enlarged Product"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleZoom();
                  }}
                  className={isZoomed ? "zoomed" : ""}
                />
                <button
                  className="modal-close-button"
                  onClick={handleCloseModal}
                >
                  &times;
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <p>No images available</p>
      )}
    </div>
  );
};

export default ProductDetailsImage;
