import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import productImage from "../../shared/images/no-image.jpg";
import "./MegaMenu.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MegaMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timerRef = useRef(null);
  const { t } = useTranslation();

  const selectedLanguage = useSelector((state) => state.language); // Get selected language from Redux
  const [openMenu, setOpenMenu] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [hoveredCategory, setHoveredCategory] = useState(null);

  // Fetch categories from API
  const fetchCategories = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL_NEW}/public/categories`
      );
      setAllCategories(res?.data?.data || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  // Fetch subcategories from API
  const fetchSubCategories = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL_NEW}/public/subcategories/`
      );
      setAllSubCategories(res?.data?.data || []);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
  }, []);

  const handleCategoryClick = (categoryId, subcategoryId = "") => {
    navigate(
      `/products?category_id=${categoryId}${
        subcategoryId ? `&subcategory_id=${subcategoryId}` : ""
      }`
    );
    setOpenMenu(false);
  };

  const handleMouseEnter = () => {
    clearTimeout(timerRef.current);
    setOpenMenu(true);
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setOpenMenu(false);
    }, 300);
  };

  const filteredSubCategories = allSubCategories.filter(
    (sub) => sub.parent_category_id === hoveredCategory?.id
  );

  return (
    <li
      className="menu-item"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a href="#" className="menu-link">
        {t("Products")}
      </a>
      <div className={`mega-menu ${openMenu ? "show" : ""}`}>
        <div className="mega-menu-content">
          <div className="categories-column">
            {allCategories?.map((cat) => (
              <div
                key={cat.id}
                className="category-item"
                onMouseEnter={() => setHoveredCategory(cat)}
                onClick={() => handleCategoryClick(cat.id)}
              >
                {/* Render category icon */}
                {cat.Image ? (
                  <img
                    src={`${cat.Image}`}
                    alt={`${
                      cat[`name_${selectedLanguage}`] || cat.name_en
                    } icon`}
                    className="category-icon-navbar"
                  />
                ) : (
                  <span className="default-icon">🔍</span> // Default icon if not found
                )}
                {/* Render category name dynamically based on language */}
                <span style={{ marginLeft: "20px" }}>
                  {cat[`name_${selectedLanguage}`] || cat.name_en}
                </span>
              </div>
            ))}
          </div>

          <div className="subcategories-column">
            {hoveredCategory ? (
              filteredSubCategories.length > 0 ? (
                filteredSubCategories.map((sub) => (
                  <div key={sub.id} className="subcategory-item">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCategoryClick(hoveredCategory.id, sub.id);
                      }}
                    >
                      <img
                        src={sub?.image || productImage}
                        alt={`${
                          sub[`name_${selectedLanguage}`] || sub.name_en
                        }`}
                        className="subcategory-icon"
                      />

                      <span style={{marginRight: "20px"}}>
                        {selectedLanguage === "kr"
                          ? sub.name_ku
                          : sub[`name_${selectedLanguage}`] || sub.name_en}
                      </span>
                    </a>
                  </div>
                ))
              ) : (
                <p className="subcategory-placeholder">
                  {t("No subcategories available")}
                </p>
              )
            ) : (
              <p className="subcategory-placeholder">
                {t("Hover over a category")}
              </p>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default MegaMenu;
