import React from "react";
import "./Checkout.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckoutCart from "./CheckoutCart";
import {
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useNavigate } from "react-router-dom";
import CheckoutAddress from "./CheckoutAddress";
import StripeCreditCard from "./StripeCreditCard";
import { Elements } from "@stripe/react-stripe-js"; // Import Elements from Stripe
import { loadStripe } from "@stripe/stripe-js"; // Import loadStripe from Stripe
import { useTranslation } from "react-i18next";
// Move loadStripe here

const CheckoutContainer = () => {
  const nav = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <div className="checkout-nav">
        <h1 className="checkout-heading">{t("Checkout")}</h1>
      </div>
      <div className="checkout-grid">
        {/* Right Side: Checkout Cart */}
        <div className="checkout-grid-template checkout-cart-container">
          <CheckoutCart />
          <div style={{ textAlign: "left" }}>
            {/* <div className="d-flex justify-content-center place-order">
                            <button className="btn btn-success w-100 my-3">Place Order</button>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutContainer;
