import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  Badge,
} from "@mui/material";
import { useSelector } from "react-redux";

const CheckoutProducts = ({ products }) => {
  // Subtotal Calculation
  const subtotal = products?.reduce((acc, product) => acc + Number(product?.price || 0), 0);

  // Dynamic Shipping Cost
  const shipping = subtotal >= 50000 ? 5000 : 7000;

  // Total Calculation
  const total = subtotal + shipping;

  // Formatting for Display
  const formattedSubtotal = subtotal.toLocaleString(); // "170,000"
  const formattedShipping = shipping.toLocaleString(); // "5,000"
  const formattedTotal = total.toLocaleString(); // "175,000"
  const selectedLanguage = useSelector((state) => state.language);
  const direction =
    selectedLanguage === "ar" || selectedLanguage === "ku" ? "rtl" : "ltr";

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "auto",
        padding: 2,
        bgcolor: "#f9f9f9",
        direction,
        textAlign: direction === "rtl" ? "right" : "left",
      }}
    >
      <Grid container spacing={2}>
        {/* Cart Items */}
        {products?.map((product, index) => (
          <React.Fragment key={index}>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" position="relative">
                  <Box
                    sx={{
                      position: "relative",
                      marginRight: direction === "rtl" ? 0 : 2,
                      marginLeft: direction === "rtl" ? 2 : 0,
                    }}
                  >
                    {/* Badge for Quantity */}
                    <Badge
                      badgeContent={product.quantity || 1}
                      color="primary"
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: direction === "rtl" ? "left" : "right",
                      }}
                    >
                      <img
                        src={product?.imageUrl}
                        alt={product?.name}
                        style={{
                          borderRadius: 4,
                          width: 50,
                          height: 50,
                        }}
                      />
                    </Badge>
                  </Box>
                  <Box>
                    <Typography variant="body1" sx={{ fontSize: "14px" }}>
                      {product?.name}
                    </Typography>
                    {product.details && (
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ mt: 1, fontSize: 12 }}
                      >
                        {product.details}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Typography variant="body2">
                  IQD {product?.price.toLocaleString()}
                </Typography>
              </Box>
            </Grid>
            {/* Divider after each product */}
            {index < products.length - 1 && (
              <Grid item xs={12}>
                <Divider sx={{ my: 1 }} />
              </Grid>
            )}
          </React.Fragment>
        ))}

        {/* Discount Code */}
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              placeholder="Discount code or gift card"
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ marginLeft: 2, minWidth: 80 }}
            >
              Apply
            </Button>
          </Box>
        </Grid>

        <Divider sx={{ my: 2 }} />

        {/* Subtotal and Shipping */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="text.secondary">
              Subtotal • {products?.length} items
            </Typography>
            <Typography variant="body2">
              IQD {formattedSubtotal}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="text.secondary">
              Shipping
            </Typography>
            <Typography variant="body2">
              IQD {formattedShipping}
            </Typography>
          </Box>
        </Grid>

        <Divider sx={{ my: 2 }} />

        {/* Total */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" fontWeight="bold">
              Total
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              IQD {formattedTotal}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckoutProducts;
