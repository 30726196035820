import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/feedback/`

export const feedbackApiService = createApi({
    reducerPath: 'feedbackApi',
    baseQuery: fetchBaseQuery({ baseUrl }),

    endpoints: (builder) => ({
        createFeedback: builder.mutation({
            query: (body) => ({
                url: 'create',
                method: "POST",
                body
            })
        })
    })
})

export const {
    useCreateFeedbackMutation
} = feedbackApiService