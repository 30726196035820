import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Box, CircularProgress, Divider } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CartProductWithDelete from "../Cart/CartProductDelete";
import Slider from "react-slick";
import axios from "axios";
import "./CartRight.css";
import {
  removeItemFromCart,
  updateItemQuantity,
} from "../../redux/Slices/Cart";
import { useTranslation } from "react-i18next";

const CartModalRight = ({ isOpen, onRequestClose }) => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { t } = useTranslation();

  // Local states for recommended products
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchRecommendedProducts = useCallback(async () => {
    if (recommendedProducts.length > 0) return;
    try {
      setIsLoading(true);
      setError(null);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_NEW}/public/recommended-products/?limit=3`
      );
      setRecommendedProducts(response?.data?.results || []);
    } catch (err) {
      console.error("Error fetching recommended products:", err);
      setError(err.message || "Unknown error occurred");
    } finally {
      setIsLoading(false);
    }
  }, [recommendedProducts]);
  
  useEffect(() => {
    if (isOpen) {
      fetchRecommendedProducts();
    }
  }, [isOpen, fetchRecommendedProducts]);
  
  // Retrieve cart items from Redux store
  const cartItems = useSelector((state) =>
    isOpen ? state.cartSlice.items : []
  );

  // Calculate subtotal
  const subtotal = useMemo(() => {
    return cartItems.reduce((total, item) => {
      const baseTotal = item.basePrice * item.quantity;
      if (
        item.quantityDiscount &&
        item.quantity >= item.quantityDiscount.quantity
      ) {
        return total + baseTotal * (1 - item.quantityDiscount.discount / 100);
      }
      return total + baseTotal;
    }, 0);
  }, [cartItems]);

  // Handle product deletion
  const handleDelete = useCallback(
    (item) => {
      dispatch(removeItemFromCart(item));
      toast.success(t("ProductRemoved"));
    },
    [dispatch, t]
  );

  // Handle quantity updates
  const handleQuantityChange = useCallback(
    (updatedItem) => {
      dispatch(updateItemQuantity(updatedItem));
    },
    [dispatch]
  );

  // Handle navigation to cart
  const handleCheckout = useCallback(() => {
    nav("/cart");
    onRequestClose();
  }, [nav, onRequestClose]);

  // Prevent body scrolling when the modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  // Reusable fragment for empty cart message
  const EmptyCartMessage = () => (
    <div className="empty-cart">
      <p>{t("OopsAdd")}</p>
    </div>
  );

  const carouselSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto sliding
    autoplaySpeed: 3000, // Set the time interval for auto slide in milliseconds
  };

  const DEFAULT_IMAGE_URL = "https://via.placeholder.com/150";

  return (
    isOpen && (
      <>
        <div className="cart-overlay open" onClick={onRequestClose}></div>
        <div className="cart-modal open">
          <div className="cart-modal-content">
            <button className="close-btn" onClick={onRequestClose}>
              &times;
            </button>
            <h3 className="cart-heading">
              {t("Products")} ({cartItems.length})
            </h3>
            <Divider className="my-3" sx={{ borderColor: "#000" }} />

            <div className="cart-items-container">
              {cartItems.length === 0 ? (
                <EmptyCartMessage />
              ) : (
                cartItems.map((item, index) => (
                  <CartProductWithDelete
                    key={index}
                    data={item}
                    onDelete={() => handleDelete(item)}
                    onQuantityChange={handleQuantityChange}
                  />
                ))
              )}
            </div>

            <Divider className="my-3" sx={{ borderColor: "#000" }} />
            <div className="cart-pricing">
              <p>{t("SubTot")}</p>
              <p>{`${subtotal?.toFixed(2)} IQD`}</p>
            </div>
            <Divider className="my-3" sx={{ borderColor: "#000" }} />

            <div className="cart-actions">
              <button className="checkout-btn" onClick={onRequestClose}>
                {t("ContinueShopping")}
              </button>
              <button
                className="checkout-btn"
                onClick={handleCheckout}
                disabled={cartItems.length === 0}
              >
                {cartItems.length === 0 ? t("AddProdCheck") : t("GoToCart")}
              </button>
            </div>
            <h3 style={{ marginTop: "20px", fontWeight: "bold" }}>
              {t("Reccomended")}
            </h3>
            <div className="recommended-products">
              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "20px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : error ? (
                <p>{t("ErrorLoading")}</p>
              ) : recommendedProducts.length > 0 ? (
                <Slider {...carouselSettings}>
                  {recommendedProducts.map((product) => (
                    <div key={product?.id} className="recommended-product-card">
                      <div className="product-image">
                        <img
                          src={product.images[0] || DEFAULT_IMAGE_URL}
                          alt={product.name_en || t("UnknownProduct")}
                        />
                      </div>
                      <div className="product-details">
                        <p style={{ fontWeight: "bold", marginBottom: 0 }}>
                          {product.name_en || t("UnknownProduct")}
                        </p>
                        <p style={{ marginBottom: 0 }}>
                          {product.price_variations[0].price
                            ? `${product.price_variations[0].price} IQD`
                            : t("PriceUnavailable")}
                        </p>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <p>{t("NoRecProducts")}</p>
              )}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default CartModalRight;
