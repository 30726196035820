import React from 'react'
import Account from '../components/Account/Account'
import About from '../components/About/About'
import ReturnsRefundPolicy from '../components/Return/Return'

const ReturnPage = () => {
  return (
    <div>
        <ReturnsRefundPolicy />
    </div>
  )
}

export default ReturnPage