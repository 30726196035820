import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Loader } from "lucide-react";
import "./style.css";

const VerifyUser = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const selectedLanguage = useSelector((state) => state.language);
  const isRTL = selectedLanguage === "ar" || selectedLanguage === "kr";
  const { t } = useTranslation();

  useEffect(() => {
    const email = searchParams.get("email");
    const securityCode = searchParams.get("code");

    if (!email || !securityCode) {
      //   toast.error(t("Invalid or missing parameters."));
      //   navigate("/error"); // Redirect to an error page if parameters are missing.
      return;
    }

    const verifyUser = async () => {
      try {
        console.log("Verifying user with payload:", { email, securityCode });
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulated API call delay
        toast.success(t("User verified successfully!"));

        setIsLoading(false); // Stop the loader

        // Redirect to login after 3 seconds
        setTimeout(() => {
          //   navigate("/login");
        }, 3000);
      } catch (error) {
        console.error("Error verifying user:", error);
        // toast.error(t("Verification failed. Please try again later."));
        setIsLoading(false);
      }
    };

    verifyUser();
  }, [searchParams, t, navigate]);

  return (
    <div
      className={`verify-container shadow ${isRTL ? "rtl" : ""}`}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "250px",
        direction: isRTL ? "rtl" : "ltr",
        textAlign: isRTL ? "right" : "center",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      {isLoading ? (
        <>
          <Loader size={48} color="#007bff" className="rotating-loader" />
          <p style={{ color: "#555", fontSize: "1.5rem", marginTop: "10px" }}>
            {t("Verifying User... Please wait.")}
          </p>
        </>
      ) : (
        <>
          <h2 style={{ marginBottom: "10px", color: "#007bff" }}>
            {t("Verification Complete")}
          </h2>
          <p style={{ color: "#555", fontSize: "1rem" }}>
            {t("Redirecting to login...")}
          </p>
        </>
      )}
    </div>
  );
};

export default VerifyUser;
