import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  CircularProgress,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useSelector } from "react-redux";
import "./Returns.css"; // Import your CSS for styling
import { Trash } from "lucide-react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddCard from "./AddCard";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SavedCards = () => {
  const { isLoggedIn, userId } = useSelector((state) => state.authSlice);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false); // Set false since we're using dummy data
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false); // State to control dialog visibility
  const navigate = useNavigate();

  // Dummy data for cards
  const dummyCards = [
    {
      id: "1",
      card: {
        brand: "Visa",
        last4: "4242",
        exp_month: 12,
        exp_year: 2025,
      },
    },
    {
      id: "2",
      card: {
        brand: "MasterCard",
        last4: "1234",
        exp_month: 8,
        exp_year: 2024,
      },
    },
  ];

  useEffect(() => {
    // Uncomment this block when ready to use the API
    // if (userId) {
    //     getUserCards();
    // }

    // Set dummy data instead
    setCards(dummyCards);
  }, [userId]);

  // API call for fetching cards - commented for now
  // const getUserCards = async () => {
  //     try {
  //         const response = await fetch(`${BASE_URL}/payment/get-user-cards`, {
  //             method: 'POST',
  //             headers: { 'Content-Type': 'application/json' },
  //             body: JSON.stringify({ userId }),
  //         });

  //         if (!response.ok) {
  //             throw new Error('Failed to fetch cards');
  //         }

  //         const data = await response.json();
  //         setCards(data.paymentMethods || []);
  //     } catch (error) {
  //         setError(error.message);
  //     } finally {
  //         setLoading(false);
  //     }
  // };

  // API call for deleting cards - commented for now
  const handleDeleteCard = (cardId) => {
    // Uncomment when ready to use the API
    // try {
    //     const response = await fetch(`${BASE_URL}/payment/delete-user-card`, {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({ userId, paymentMethodId: cardId }),
    //     });

    //     if (!response.ok) {
    //         throw new Error('Failed to delete card');
    //     }

    //     toast.success('Card deleted successfully!');
    //     getUserCards(); // Refresh the cards
    // } catch (error) {
    //     console.error('Delete error:', error);
    //     toast.error('Error deleting card.');
    // }

    // Dummy delete action
    setCards(cards.filter((card) => card.id !== cardId));
    toast.success("Card deleted successfully!");
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    // Refresh the cards after adding a new one - Use API when ready
    // getUserCards();
  };

  return (
    <div className="my-order-container" style={{ padding: "20px" }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        My Cards
      </Typography>

      {/* Add Card Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenDialog}
        sx={{ mb: 2 }}
      >
        Add Card
      </Button>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </div>
      ) : cards.length === 0 ? (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", color: "#757575" }}
          >
            You have no saved cards yet.
          </Typography>
        </div>
      ) : (
        <Grid container spacing={5}>
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={12} key={index}>
              <Card
                variant="outlined"
                sx={{
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  padding: "10px",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    {card.card.brand}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: "1rem" }}
                  >
                    **** **** **** {card.card.last4}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ fontSize: "0.875rem" }}
                  >
                    {card.card.exp_month}/{card.card.exp_year}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    <IconButton
                      onClick={() => handleDeleteCard(card.id)}
                      sx={{ color: "#f44336" }}
                    >
                      <Trash size={20} />
                    </IconButton>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Add Card Dialog */}
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>Add a New Card</DialogTitle>
        <DialogContent>
          <Elements stripe={stripePromise}>
            <AddCard onClose={handleCloseDialog} />
          </Elements>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SavedCards;
