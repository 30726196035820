import { useState } from "react";
import { Select, MenuItem } from "@mui/material";
import Flag from "react-world-flags";

const LanguageSelector = ({ handleLanguageChange, currentLanguage }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  const handleChange = (event) => {
    const lang = event.target.value;
    setSelectedLanguage(lang);
    handleLanguageChange(lang);
    localStorage.setItem("language", lang || "en");

    const htmlTag = document.documentElement;

    if (lang === "ar" || lang === "kr") {
      htmlTag.setAttribute("dir", "rtl");
    } else {
      htmlTag.setAttribute("dir", "ltr");
    }
  };

  const customStyle = {
    width: "35px",
    // height: "32px",
    marginTop: "0px",
    marginLeft: "7px",
  };
  return (
    <Select
      value={selectedLanguage}
      onChange={handleChange}
      displayEmpty
      inputProps={{ "aria-label": "Language Selector" }}
      sx={{
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        "& fieldset": {
          border: "none", // Remove border
        },
        "&:focus": {
          outline: "none", // Remove blue focus outline
        },
      }}
    >
      {/* English - US Flag */}
      <MenuItem value="en">
      <img
          src="https://cdn.britannica.com/79/4479-050-6EF87027/flag-Stars-and-Stripes-May-1-1795.jpg"
          alt="Kurdistan Flag"
          style={customStyle}
        />
      </MenuItem>

      {/* Swedish - Sweden Flag */}
      {/* <MenuItem value="swe">
        <Flag code="SE" style={getFlagStyles()} />
      </MenuItem> */}

      {/* Arabic - Iraq Flag */}
      <MenuItem value="ar">
        <Flag
          style={customStyle}
          code="IQ"
          // style={getFlagStyles()}
        />
      </MenuItem>

      {/* Kurdish - Kurdistan Flag */}
      <MenuItem value="kr">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Flag_of_Kurdistan.svg/640px-Flag_of_Kurdistan.svg.png"
          alt="Kurdistan Flag"
          style={customStyle}
        />
      </MenuItem>
    </Select>
  );
};

export default LanguageSelector;
