import * as React from "react";
import { Box, IconButton, Typography, Paper, Button } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  removeItemFromCart,
  updateItemQuantity,
} from "../../redux/Slices/Cart";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CartTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cartSlice.items);
  const { isLoggedIn } = useSelector((state) => state.authSlice);
  const { token } = useSelector((state) => state.authSlice);
  const { t } = useTranslation();

  const handleQuantityChange = ({
    productId,
    size,
    color,
    variation,
    quantity,
    quantityDiscount,
    stock,
    scale,
  }) => {
    if (quantity > 0) {
      dispatch(
        updateItemQuantity({
          productId,
          size,
          color,
          variation,
          quantity: parseInt(quantity, 10),
          quantityDiscount,
          stock,
          scale,
          isLoggedIn,
          token,
        })
      );
    }
  };

  const handleDelete = (item) => {
    dispatch(removeItemFromCart({ ...item, isLoggedIn, token }));
  };

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  // Placeholder for broken images or no image
  const handleImageError = (e) => {
    e.target.style.display = "none"; // Hide broken image
    e.target.nextSibling.style.display = "flex"; // Show placeholder box
  };

  if (cartItems.length === 0) {
    return <p className="empty-cart-message">{t("No items in the cart")}</p>;
  }

  return (
    <Paper
      sx={{
        padding: 2,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        border: "1px solid #ddd",
        borderRadius: 2,
      }}
    >
      {cartItems.map((item) => (
        <Box
          key={item.productId}
          sx={{
            display: "flex",
            alignItems: "center",
            paddingY: 2,
            borderBottom: "1px solid #eee",
            "&:last-child": { borderBottom: "none" },
            "@media (max-width: 600px)": {
              flexDirection: "column",
              alignItems: "flex-start",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: 60,
              height: 60,
              borderRadius: 2,
              marginRight: 2,
              overflow: "hidden",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
              backgroundColor: "#f5f5f5",
              cursor: "pointer",
            }}
            onClick={() => handleProductClick(item.productId)}
          >
            {/* Image */}
            <img
              src={item.imageUrl || "broken-image-url.jpg"}
              alt={item.name}
              onError={handleImageError}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: "block",
              }}
            />
            {/* Placeholder */}
            <Box
              sx={{
                display: "none",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                backgroundColor: "#e0e0e0",
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "#555",
              }}
            >
              {item.name ? item.name.charAt(0).toUpperCase() : "P"}
            </Box>
          </Box>

          <Box sx={{ flex: 1, minWidth: 0 }}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                cursor: "pointer",
                color: "#333",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textWrap: "auto"
              }}
              onClick={() => handleProductClick(item.productId)}
            >
              {item.name || "Product Name"}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", marginRight: 12 }}>
            <IconButton
              onClick={() =>
                handleQuantityChange({ ...item, quantity: item.quantity - 1 })
              }
              disabled={item.quantity <= 1}
              sx={{
                backgroundColor: "#f5f5f5",
                "&:hover": { backgroundColor: "#eee" },
                padding: "6px",
                color: "#666",
              }}
            >
              <RemoveIcon fontSize="small" />
            </IconButton>
            <Typography variant="body2" sx={{ marginX: 1, color: "#333" }}>
              {item.quantity}
            </Typography>
            <IconButton
              onClick={() =>
                handleQuantityChange({ ...item, quantity: item.quantity + 1 })
              }
              sx={{
                backgroundColor: "#f5f5f5",
                "&:hover": { backgroundColor: "#eee" },
                padding: "6px",
                color: "#666",
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Box>

          <Box sx={{ minWidth: 80, textAlign: "right" }}>
            <Typography variant="body2" sx={{ fontWeight: 600, color: "#333" }}>
              {item.basePrice} IQD
            </Typography>
          </Box>

          <Button
            onClick={() => handleDelete(item)}
            sx={{
              marginLeft: 2,
              color: "#999",
              fontSize: "0.75rem",
              textTransform: "none",
              "&:hover": { color: "#333" },
            }}
          >
            {t("Remove")}
          </Button>
        </Box>
      ))}
    </Paper>
  );
};

export default CartTable;
