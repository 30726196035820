import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = `${process.env.REACT_APP_BASE_URL_NEW}/public/products/`;
console.log(baseUrl, "baseUrl--------");

export const productApiService = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (args) => {
        // Log the arguments passed to the query

        // Check if an ID is provided to fetch a specific product
        if (args?.id) {
          return `get/${args.id}`; // Fetch a specific product
        }

        // Construct the query object directly from the args
        const query = {
          page: args.page || 1, // Default to page 1 if not provided
          rowsPerPage: args.rowsPerPage || 9, // Default to 9 if not provided
          search: args.search || "",
          sortBy: args.sortBy || "",
          category_id: args.category_id || "",
          subcategory_id: args.subcategory_id || "",
          brand_id: args.brand_id || "",
          minPrice: args.minPrice || "",
          maxPrice: args.maxPrice || "",
        };

        // Log the constructed query parameters

        // Return the endpoint with query parameters as an object
        return {
          // url: "get",
          // params: query,
        };
      },
      keepUnusedDataFor: 0,
    }),
    getRandom: builder.query({
      query: () => {
        // Log the call to the random products endpoint

        // Return the endpoint for fetching random products
        return "random"; // Change to your random products endpoint
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetRandomQuery, // Fixed this to follow the naming convention
} = productApiService;
