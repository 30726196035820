import { createSlice } from "@reduxjs/toolkit"
import { generalApiService } from "../../Apis/General"

let initialState = {
    brands: [],
    categories: [],
    strength: []
}

export const GeneralSlice = createSlice({
    name: 'generalSlice',
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(generalApiService.endpoints.getLOV.matchFulfilled, (state, action) => {
            let { success, data } = action?.payload
            if (success) {
                state.brands = data?.brands
                state.categories = data?.categories
                state.strength = data?.strength
            }
        })
    }
})

export const { } = GeneralSlice.actions
export default GeneralSlice.reducer
