import React from 'react';
import './PrivacyPolicy.css'; // Ensure this path is correct based on your project structure
import { useTranslation } from 'react-i18next';


const PrivacyPolicy = () => {
    const { t } = useTranslation();
    return (
        <section className="about-section">
            <div className="about-grid">
                <div className="about-text">
                    <h2 className="about-heading">{t("Privacy Policy")}</h2>
                    <p className="about-para">
                        {t("P1")}                    </p>
                    <p className="about-para">
                        {t("P2")}</p>

                    <h3 className="about-section-heading">{t("P3")}</h3>
                    <p className="about-detail-para">

                        {t('about.detailParagraph1')}                    </p>
                    <p className="about-detail-para">
                        {t('about.detailParagraph2')}
                    </p>
                    <ul className="about-detail-para">
                        <li>{t('about.cookies.enhancingUserExperience')}</li>
                        <li>{t('about.cookies.websitePerformanceAndAnalytics')}</li>
                        <li>{t('about.cookies.securityAndFraudPrevention')}</li>
                        <li>{t('about.cookies.personalizedContentAndMarketing')}</li>
                        <li>{t('about.cookies.facilitatingOnlineServices')}</li>
                    </ul>

                    <h3 className="about-section-heading">
                        {t('about.dataCollection.heading')}
                    </h3>
                    <p className="about-detail-para">
                        {t('about.dataCollection.detail')}
                    </p>
                    <ul className="about-detail-para">
                        <li>{t('about.dataCollection.methods.accountRegistration')}</li>
                        <li>{t('about.dataCollection.methods.orderForms')}</li>
                        <li>{t('about.dataCollection.methods.cookiesTracking')}</li>
                        <li>{t('about.dataCollection.methods.customerService')}</li>
                        <li>{t('about.dataCollection.methods.thirdPartyIntegrations')}</li>

                    </ul>

                    <h3 className="about-section-heading">
                        {t('about.dataStorage.heading')}
                    </h3>
                    <p className="about-detail-para">
                        {t('about.dataStorage.detail')}
                    </p>



                    <h3 className="about-section-heading">
                        {t('about.cookiePolicy.heading')}
                    </h3>
                    <p className="about-detail-para">
                    {t('about.dataStorage.detail')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default PrivacyPolicy;
