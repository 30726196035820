import React, { useEffect, useRef, useState } from "react";
import ProductDetailsImage from "./ProductDetailsImage";
import "./ProductDetails.css";
import ProductInformation from "./Information/ProductInformation";
import ProductSpecRating from "./ProductSpecRating";
import Product from "../ProductComponent/Product";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  Tabs,
  Tab,
  Box,
  Rating,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetRandomQuery } from "../../redux/Apis/Products";
import HomeProduct from "../ProductComponent/HomeProduct";
import { useTranslation } from "react-i18next";
import { useGetProductReviewsQuery } from "../../redux/Apis/Review";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Slider from "react-slick";
import ProductSlider from "../Trending/ProductSlider";
import Loader from "../Loader/Loader";
import ReviewModal from "./ReviewModal";

const ProductDetails = ({ data, loading, fetchProductDetails }) => {
  const { t } = useTranslation();
  const [recentlyViewedProducts, setRecentlyViewedProducts] = useState([]);
  const isCookieConsentAccepted = useSelector(
    (state) => state.cookieConsent.isAccepted
  );
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  const [tabValue, setTabValue] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedLanguage = useSelector((state) => state.language);

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (isCookieConsentAccepted && data?._id) {
      let productIds = [];
      const mainCookie = Cookies.get("recentlyViewedProducts");
      if (mainCookie) {
        try {
          productIds = JSON.parse(mainCookie);
        } catch (e) {
          console.error("Error parsing main cookie:", e);
        }
      }

      if (!Array.isArray(productIds)) {
        productIds = [];
      }

      const isProductStored = productIds.includes(data._id);

      if (!isProductStored) {
        productIds = [data._id, ...productIds];
        Cookies.set("recentlyViewedProducts", JSON.stringify(productIds), {
          expires: 7,
        });
        Cookies.set(`recentlyViewedProduct_${data._id}`, JSON.stringify(data), {
          expires: 7,
        });
      }

      const recentlyViewedProducts = productIds
        .map((id) => {
          const productCookie = Cookies.get(`recentlyViewedProduct_${id}`);
          return productCookie ? JSON.parse(productCookie) : null;
        })
        .filter((product) => product !== null);

      setRecentlyViewedProducts(recentlyViewedProducts);
      setIsLoadingProducts(false);
    }
  }, [isCookieConsentAccepted, data?._id]);

  return loading ? (
    <Loader />
  ) : (
    <div className="product-detail-page">
      {/* Category Section */}
      <div className="category-section">
        <Link to={`/products?category_id=${data?.category}`}>
          {data?.category_name}
        </Link>
        <span>/</span>
        <Link
          to={`/products?category_id=${data?.category}${
            data?.subcategory ? `&subcategory_id=${data?.subcategory}` : ""
          }`}
        >
          {data?.subcategory_name}
        </Link>
      </div>

      {/* Product Top Section */}
      <div className="product-top-section">
        <div className="product-images-section">
          <ProductDetailsImage images={data?.images} />
        </div>

        <div className="product-extra-section">
          <ProductInformation data={data} />
        </div>
      </div>

      {/* Product Accordion Section */}
      <Box sx={{ width: "100%", backgroundColor: "white", marginTop: 2 }}>
        {/* Accordion for Description */}
        <Accordion sx={{ marginBottom: 0 }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="description-content"
            id="description-header"
          >
            <strong>{t("Description")}</strong>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {(selectedLanguage === "en" && data?.description_en) ||
                (selectedLanguage === "ar" && data?.description_ar) ||
                (selectedLanguage === "kr" && data?.description_ku)}
            </div>
          </AccordionDetails>
        </Accordion>

        {/* Accordion for Reviews */}
        <Accordion sx={{ marginTop: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="reviews-content"
            id="reviews-header"
          >
            <strong>{t("Reviews")}</strong>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { sm: "row", xs: "column" },
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <p style={{ margin: 0, fontSize: "40px" }}>4</p>
                    <Box
                      sx={{
                        "& .MuiRating-iconEmpty": { color: "#cfd8dc" },
                      }}
                    >
                      <Rating
                        name="read-only"
                        value={Math.floor(data?.rating)}
                        precision={1}
                        readOnly
                      />
                    </Box>
                  </div>
                  <p style={{ margin: 0 }}>
                    ({data?.review_count} {t("reviews")})
                  </p>
                </div>
                {/* Rating Breakdown */}
                <Box
                  sx={{
                    marginTop: "1rem",
                    width: "250px",
                    marginLeft: { sm: "2rem" },
                  }}
                >
                  {[5, 4, 3, 2, 1].map((star) => {
                    const count =
                      data?.rating_distribution?.[String(star)] || 0;
                    const percentage = data?.review_count
                      ? (count / data.review_count) * 100
                      : 0;

                    return (
                      <div
                        key={star}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          marginBottom: "8px",
                        }}
                      >
                        <Rating
                          value={star}
                          max={5}
                          readOnly
                          size="small"
                          sx={{
                            "& .MuiRating-iconFilled": { color: "gray" },
                          }}
                        />
                        <Box
                          sx={{
                            flex: 1,
                            backgroundColor: "#e0e0e0",
                            borderRadius: "5px",
                            overflow: "hidden",
                            height: "8px",
                          }}
                        >
                          <Box
                            sx={{
                              width: `${percentage}%`,
                              backgroundColor: "#ffb400",
                              height: "100%",
                            }}
                          />
                        </Box>
                        <span style={{ minWidth: "30px", textAlign: "center" }}>
                          ({count})
                        </span>
                      </div>
                    );
                  })}
                </Box>
                <Button
                  sx={{
                    marginTop: "1rem",
                    height: "40px",
                    marginLeft: { sm: "auto" },
                    color: "white",
                    backgroundColor: "black",
                    textTransform: "none",
                    border: "1px solid transparent",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "white",
                      border: "1px solid black",
                    },
                  }}
                  onClick={handleModalOpen}
                >
                  {t("Write a Review")}
                </Button>
              </Box>

              <div style={{ marginTop: "2rem" }}>
                {data?.reviews?.map((review, index) => (
                  <Box
                    key={index}
                    sx={{
                      marginBottom: "1.5rem",
                      padding: "1rem",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <strong>{review.name}</strong>
                      <Rating
                        name={`rating-${index}`}
                        value={review.rating}
                        precision={0.5}
                        readOnly
                        size="small"
                      />
                    </div>
                    <p style={{ margin: "0.5rem 0 0" }}>{review.comment}</p>
                  </Box>
                ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* People Also Bought Section */}
      <div className="product-related-section">
        <div className="product-slider-container">
          {/* Conditionally render based on cookie consent and length of recentlyViewedProducts */}
        </div>
      </div>
      <ReviewModal open={isModalOpen} handleClose={handleModalClose} product={data.id} fetchProductDetails={fetchProductDetails} />
    </div>
  );
};

export default ProductDetails;
