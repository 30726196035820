import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  useGetUserAddressesQuery,
  useDeleteUserAddressMutation,
  useUpdateUserAddressMutation,
  useAddUserAddressMutation,
} from "../../redux/Apis/User";
import { use } from "i18next";
import Loader from "../Loader/Loader";

function AddressCard({ elem, refetch, setLoading }) {
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editedData, setEditedData] = useState({
    first_name: elem.first_name,
    last_name: elem.last_name,
    city: elem.city,
    governorate: elem.governorate,
    addressLine1: elem.addressLine1,
    postalCode: elem.postalCode,
    phone: elem.phone,
  });

  const [updateUserAddress] = useUpdateUserAddressMutation();
  const [deleteUserAddress] = useDeleteUserAddressMutation();

  const handleDialogOpen = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  // Open the delete confirmation dialog
  const handleDelete = () => {
    setDeleteDialogOpen(true);
  };

  // Close the delete confirmation dialog
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  // Confirm delete action
  const handleConfirmDelete = async () => {
    setLoading(true);
    await deleteUserAddress({ addressId: elem?.id });
    await refetch();
    setLoading(false);
    setDeleteDialogOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    await updateUserAddress({
      addressId: elem.id,
      ...editedData,
    });
    await refetch();
    setLoading(false);
    setOpen(false);
  };
  return (
    <div>
      <Card variant="outlined" sx={{ margin: "1rem 0" }}>
        <CardContent>
          <Typography color="textPrimary" fontSize="1rem">
            <strong>
              {elem.first_name} {elem.last_name}
            </strong>
          </Typography>
          <Typography color="textSecondary" fontSize="1rem">
            <strong>Phone:</strong> {elem.phone}
          </Typography>
          <Typography color="textSecondary" fontSize="1rem">
            <strong>Address:</strong> {elem.addressLine1}
          </Typography>
          <Typography color="textSecondary" fontSize="1rem">
            <strong>City:</strong> {elem.city}
          </Typography>
          <Typography color="textSecondary" fontSize="1rem">
            <strong>Postal Code:</strong> {elem.postalCode}
          </Typography>
          <Typography color="textSecondary" fontSize="1rem">
            <strong>Governorate:</strong> {elem.governorate}
          </Typography>

          <div className="my-2">
            <DeleteIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              className="me-1"
              onClick={handleDelete}
            />
            <EditIcon
              fontSize="small"
              sx={{ cursor: "pointer" }}
              className="me-1"
              onClick={handleDialogOpen}
            />
          </div>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleDialogClose} style={{ zIndex: 1000 }}>
        <DialogTitle>Edit Information</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            fullWidth
            name="first_name"
            value={editedData.first_name}
            onChange={handleInputChange}
            sx={{ marginBottom: "1rem", marginTop: "1rem" }}
          />
          <TextField
            label="Last Name"
            fullWidth
            name="last_name"
            value={editedData.last_name}
            onChange={handleInputChange}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            label="Phone"
            fullWidth
            name="phone"
            value={editedData.phone}
            onChange={handleInputChange}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            label="Address"
            fullWidth
            name="addressLine1"
            value={editedData.addressLine1}
            onChange={handleInputChange}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            label="City"
            fullWidth
            name="city"
            value={editedData.city}
            onChange={handleInputChange}
            sx={{ marginBottom: "1rem" }}
          />
          <TextField
            label="Postal Code"
            fullWidth
            name="postalCode"
            value={editedData.postalCode}
            onChange={handleInputChange}
            sx={{ marginBottom: "1rem" }}
          />
          <FormControl fullWidth>
            <InputLabel id="governorate-select-label">Governorate</InputLabel>
            <Select
              labelId="governorate-select-label"
              name="governorate"
              value={editedData.governorate}
              onChange={handleInputChange}
              label="Governorate"
            >
              <MenuItem value="Baghdad">Baghdad - بغداد</MenuItem>
              <MenuItem value="Basra">Basra - البصرة</MenuItem>
              <MenuItem value="Mosul">Mosul - الموصل</MenuItem>
              <MenuItem value="Erbil">Erbil - أربيل</MenuItem>
              <MenuItem value="Karbala">Karbala - كربلاء</MenuItem>
              <MenuItem value="Najaf">Najaf - النجف</MenuItem>
              <MenuItem value="Kirkuk">Kirkuk - كركوك</MenuItem>
              <MenuItem value="Sulaymaniyah">
                Sulaymaniyah - السليمانية
              </MenuItem>
              <MenuItem value="Fallujah">Fallujah - الفلوجة</MenuItem>
              <MenuItem value="Ramadi">Ramadi - الرمادي</MenuItem>
              <MenuItem value="Samarra">Samarra - سامراء</MenuItem>
              <MenuItem value="Nasiriyah">Nasiriyah - الناصرية</MenuItem>
              <MenuItem value="Duhok">Duhok - دهوك</MenuItem>
              <MenuItem value="Amara">Amara (Al-Amarah) - العمارة</MenuItem>
              <MenuItem value="Tikrit">Tikrit - تكريت</MenuItem>
              <MenuItem value="Baqubah">Baqubah - بعقوبة</MenuItem>
              <MenuItem value="Zakho">Zakho - زاخو</MenuItem>
              <MenuItem value="Hilla">Hilla - الحلة</MenuItem>
              {/* Other MenuItems */}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this address?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const AddressList = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressLine1, setAddressLine1] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [governorate, setGovernorate] = useState("");

  const { data: addresses, isLoading, refetch } = useGetUserAddressesQuery();
  const [addUserAddress] = useAddUserAddressMutation();

  useEffect(() => {
    refetch();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddAddress = async () => {
    setLoading(true);
    await addUserAddress({
      first_name: firstName,
      last_name: lastName,
      city,
      governorate,
      addressLine1,
      postalCode,
      country: "Iraq",
      phone,
    });
    await refetch();
    handleClose();
    setAddressLine1("");
    setPostalCode("");
    setPhone("");
    setFirstName("");
    setLastName("");
    setCity("");
    setGovernorate("");
    handleClose();
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ margin: "auto", width: "100%" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            sx={{ marginBottom: 2 }}
          >
            Add Address
          </Button>

          {addresses && addresses?.results?.length > 0 ? (
            <Grid container spacing={2}>
              {addresses?.results?.map((elem) => (
                <Grid item xs={12} sm={6} key={elem.id}>
                  <AddressCard
                    elem={elem}
                    refetch={refetch}
                    setLoading={setLoading}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No addresses found.</Typography>
          )}

          <Dialog open={open} onClose={handleClose} style={{ zIndex: 1000 }}>
            <DialogTitle>Add New Address</DialogTitle>
            <DialogContent>
              <TextField
                label="First Name"
                fullWidth
                name="first_name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                sx={{ marginBottom: "1rem" }}
              />
              <TextField
                label="Last Name"
                fullWidth
                name="last_name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                sx={{ marginBottom: "1rem" }}
              />
              <TextField
                label="Phone"
                fullWidth
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                sx={{ marginBottom: "1rem" }}
              />
              <TextField
                label="Address"
                fullWidth
                name="addressLine1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
                sx={{ marginBottom: "1rem" }}
              />
              <TextField
                label="City"
                fullWidth
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                sx={{ marginBottom: "1rem" }}
              />
              <TextField
                label="Postal Code"
                fullWidth
                name="postalCode"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                sx={{ marginBottom: "1rem" }}
              />
              <FormControl fullWidth>
                <InputLabel id="governorate-select-label">
                  Governorate
                </InputLabel>
                <Select
                  labelId="governorate-select-label"
                  name="governorate"
                  value={governorate}
                  onChange={(e) => setGovernorate(e.target.value)}
                  label="Governorate"
                >
                  <MenuItem value="Baghdad">Baghdad - بغداد</MenuItem>
                  <MenuItem value="Basra">Basra - البصرة</MenuItem>
                  <MenuItem value="Mosul">Mosul - الموصل</MenuItem>
                  <MenuItem value="Erbil">Erbil - أربيل</MenuItem>
                  <MenuItem value="Karbala">Karbala - كربلاء</MenuItem>
                  <MenuItem value="Najaf">Najaf - النجف</MenuItem>
                  <MenuItem value="Kirkuk">Kirkuk - كركوك</MenuItem>
                  <MenuItem value="Sulaymaniyah">
                    Sulaymaniyah - السليمانية
                  </MenuItem>
                  <MenuItem value="Fallujah">Fallujah - الفلوجة</MenuItem>
                  <MenuItem value="Ramadi">Ramadi - الرمادي</MenuItem>
                  <MenuItem value="Samarra">Samarra - سامراء</MenuItem>
                  <MenuItem value="Nasiriyah">Nasiriyah - الناصرية</MenuItem>
                  <MenuItem value="Duhok">Duhok - دهوك</MenuItem>
                  <MenuItem value="Amara">Amara (Al-Amarah) - العمارة</MenuItem>
                  <MenuItem value="Tikrit">Tikrit - تكريت</MenuItem>
                  <MenuItem value="Baqubah">Baqubah - بعقوبة</MenuItem>
                  <MenuItem value="Zakho">Zakho - زاخو</MenuItem>
                  <MenuItem value="Hilla">Hilla - الحلة</MenuItem>
                  {/* Other MenuItems */}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleAddAddress}
                color="primary"
                disabled={
                  !addressLine1 ||
                  postalCode.length !== 5 ||
                  !phone ||
                  !firstName ||
                  !lastName ||
                  !city ||
                  !governorate
                }
              >
                Add Address
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default AddressList;
