import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import ProductCard from "./ProductCard";
import "./Slider.css";

const ProductSlider = ({ products }) => {
  const productCount = products?.length || 0;

  // Dynamically calculate slidesToShow and slidesToScroll
  const getSlidesToShow = (screenProductCount, defaultCount) =>
    Math.min(productCount, screenProductCount || defaultCount);

  const responsiveSettings = [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: getSlidesToShow(4, 4), // Up to 4 slides for large screens
        slidesToScroll: getSlidesToShow(4, 4),
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: getSlidesToShow(3, 3), // 3 slides for medium-large screens
        slidesToScroll: getSlidesToShow(3, 3),
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: getSlidesToShow(2, 2), // 2 slides for tablets and smaller laptops
        slidesToScroll: getSlidesToShow(2, 2),
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: getSlidesToShow(1, 1), // 1 slide for small tablets
        slidesToScroll: getSlidesToShow(1, 1),
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1, // Always at least 1 slide visible
        slidesToScroll: 1,
      },
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: Math.min(productCount, 4), // Default: max 4 slides
    slidesToScroll: Math.min(productCount, 4),
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    responsive: responsiveSettings,
  };

  return (
    <div className="slider-container-2">
      <Slider {...settings}>
        {products?.map((product, index) => (
          <ProductCard key={index} data={product} />
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider;
