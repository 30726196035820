import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = `${process.env.REACT_APP_BASE_URL}/settings/`;

export const settingApiService = createApi({
    reducerPath: 'settingsApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const { token } = getState().authSlice; // Destructuring 'token' from authSlice state
            if (token) {
                headers.set('Authorization', `Bearer ${token}`); // Setting the Authorization header
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getSettings: builder.query({
            query: () => ({
                url: 'get',
                method: 'GET',
            }),
        }),
        createOrSettings: builder.mutation({
            query: (args) => ({
                url: 'create',
                method: 'POST',
                body: args,
            }),
            
        }),


    }),
});

export const {
    useGetSettingsQuery,
    useCreateOrSettingsMutation

} = settingApiService;
