import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { encodeQueryData } from '../../../utils/helper'

const baseUrl = `${process.env.REACT_APP_BASE_URL}/home-settings/`

export const homeSettingsApiService = createApi({
    reducerPath: 'homeSettingsApi',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        getHomeSettings: builder.query({
            query: (args) => {
                if (args?.id) {
                    return `get/${args?.id}`
                }
                let query = encodeQueryData(args)
                return `get?${query}`
            },
            // Refetch every time the component mounts or arguments change
            refetchOnMountOrArgChange: true,
            keepUnusedDataFor: 0, // Do not cache any unused data
        })
    })
})

export const {
    useGetHomeSettingsQuery
} = homeSettingsApiService