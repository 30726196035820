import React from "react";
import CartContainer from "../components/Cart/CartContainer";
import { Stack } from "@mui/material";

const CartPage = () => {
  return (
    <Stack>
      <CartContainer />
    </Stack>
  );
};

export default CartPage;
