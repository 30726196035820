import React from "react";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "../components/Privacy/Privacy";

const PrivacyPage = () => {
  return (
    <div>
      {/* React Helmet for SEO */}
      <Helmet>
        <title>Minasatkom | سياسة الخصوصية</title>
        <meta
          name="description"
          content="اقرأ سياسة الخصوصية الخاصة بـ Minasatkom لفهم كيفية تعاملنا مع بياناتك وحماية خصوصيتك."
        />
        <meta
          name="keywords"
          content="سياسة الخصوصية، Minasatkom، حماية البيانات، المعلومات الشخصية، السرية"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Minasatkom | سياسة الخصوصية" />
        <meta
          property="og:description"
          content="نحن نقدر خصوصيتك. اقرأ سياسة الخصوصية الخاصة بنا للحصول على تفاصيل حول كيفية جمعنا واستخدامنا لمعلوماتك."
        />
        <meta property="og:image" content="/path/to/privacy-og-image.jpg" />
        <meta property="og:url" content="https://minasatkom.com/privacy" />
        <meta property="og:type" content="article" />
      </Helmet>

      {/* Privacy Policy Content */}
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPage;
