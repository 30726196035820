import React from 'react'
import ChangePasswordForm from '../components/Auth/ChangePassword'

const ChangePassword = () => {
  return (
    <div>
        <ChangePasswordForm />
    </div>
  )
}

export default ChangePassword