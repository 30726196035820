// src/redux/languageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import i18n from "../../../shared/i18n/i18n"; // Import your i18n config

const storedLanguage = localStorage.getItem("language") || "en"; // Fetch the stored language from localStorage

const languageSlice = createSlice({
  name: "language",
  initialState: storedLanguage, // Initialize with stored language or fallback to 'en'
  reducers: {
    setLanguage: (state, action) => {
      const language = action.payload;
      i18n.changeLanguage(language); // Change language in i18n
      // localStorage.setItem("language", language); // Save the language to localStorage
      return language; // Update Redux state
    },
  },
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
