import React, { useEffect, useState } from "react";
import { useGetProductsQuery } from "../../redux/Apis/Products";
import Product from "../ProductComponent/Product";
import "./ProductSearch.css";
import loadgif from "../../shared/images/loadinggif.gif";
import { Button } from "@mui/material";
import { useSearchProductsQuery } from "../../redux/Apis/Search";
import { useGetAllCategoriesWithSubcategoriesQuery } from "../../redux/Apis/Category";
import { useGetActiveBrandsQuery } from "../../redux/Apis/Brands";
import {
  Box,
  TextField,
  Typography,
  Slider,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import ReactSlider from "react-slider";
import { useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import ProductCard from "../Trending/ProductCard";

const ProductSearch = () => {
  const { term } = useParams(); // assuming the route is set up like /search/:term
  const [searchTerm, setSearchTerm] = useState(term || "");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [sortBy, setSortBy] = useState("priceDesc");
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false); // Loading state for products
  const [selectedCategoryId, setSelectedCategoryId] = useState();
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState();
  const [selectedBrandId, setSelectedBrandId] = useState();
  const [categoryId, setCategoryId] = useState();
  const [subcategoryId, setSubcategoryId] = useState();
  const [brandId, setBrandId] = useState();
  const [showCategories, setShowCategories] = useState(false);
  const [showBrands, setShowBrands] = useState(false);
  // Fetch products based on search term and price range
  const {
    data: productsResponse,
    error: productsError,
    isLoading: productsLoading,
    refetch,
  } = useSearchProductsQuery({
    searchTerm,
    minPrice,
    maxPrice,
    sortBy,
    category: selectedCategoryId,
    subcategory: selectedSubcategoryId,
    brand: selectedBrandId,
  });

  useEffect(() => {
    setLoadingProducts(true); // Set loading to true before fetching products
    refetch().finally(() => setLoadingProducts(false)); // Reset loading after fetching
  }, [searchTerm, minPrice, maxPrice, refetch]);

  useEffect(() => {
    if (productsResponse && productsResponse.success) {
      // Access the products array in the response data
      setProducts(productsResponse.data.products);
    }
  }, [productsResponse]);

  useEffect(() => {
    setSearchTerm(term || ""); // Update searchTerm when the term changes
  }, [term]); // Dependency array includes term

  const clearFilters = () => {
    setSearchTerm("");
    setMinPrice("");
    setMaxPrice("");
    setSelectedCategoryId("");
    setSelectedSubcategoryId("");
    setSelectedBrandId("");
    setCategoryId("");
    setBrandId("");
    setSubcategoryId("");
    setProducts([]); // Clear products on filter reset
    refetch(); // Refetch products after clearing filters
  };

  const {
    data: categoriesResponse,
    error: categoriesError,
    isLoading: categoriesLoading,
  } = useGetAllCategoriesWithSubcategoriesQuery();

  // Fetch brands
  const {
    data: brandsResponse,
    error: brandsError,
    isLoading: brandsLoading,
  } = useGetActiveBrandsQuery();

  const handleSliderChange = (event, newValue) => {
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
  };

  return (
    <div className="product-search-container">
      {/* Left Sidebar for Sorting */}
      <div className="left-sidebar">
        <Box
          className="search-container"
          sx={{
            p: 2,
            background: "#fff",
            borderRadius: 2,
            boxShadow: 1,
            maxWidth: 400,
          }}
        >
          <TextField
            label="Search for products..."
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              className: "full-width-input",
            }}
          />
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#333" }}
            >
              Price Range
            </Typography>
            <Slider
              value={[minPrice || 0, maxPrice || 5000]}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              min={0}
              max={10000}
              sx={{ color: "#4dc1f7" }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                fontSize: 14,
                color: "#888",
              }}
            >
              <span>kr {minPrice || 0}</span>
              <span>kr {maxPrice || 10000}</span>
            </Box>
          </Box>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              label="Sort By"
            >
              <MenuItem value="priceAsc">Pris - Lågt till Högt</MenuItem>
              <MenuItem value="priceDesc">Pris - Högt till Lågt</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Categories */}
        <div className="categories">
          <h2
            onClick={() => setShowCategories(!showCategories)}
            className="dropdown-header"
          >
            Categories <span>{showCategories ? "▼" : "►"}</span>
          </h2>
          <ul className={`dropdown-list ${showCategories ? "expanded" : ""}`}>
            {showCategories &&
              categoriesResponse.data.map((category) => (
                <li
                  key={category._id}
                  className={
                    selectedCategoryId === category._id ? "selected" : ""
                  }
                >
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedCategoryId === category._id}
                      onChange={() => {
                        const newSelectedCategoryId =
                          selectedCategoryId === category._id
                            ? null
                            : category._id;
                        setCategoryId(newSelectedCategoryId);
                        setSelectedCategoryId(newSelectedCategoryId);
                        if (newSelectedCategoryId === null) {
                          setSubcategoryId(null);
                          setSelectedSubcategoryId(null);
                        }
                      }}
                    />
                    <span>{category.name}</span>
                  </label>
                  {category.subcategories.length > 0 && (
                    <ul>
                      {category.subcategories.map((subcategory) => (
                        <li key={subcategory._id}>
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                selectedCategoryId === category._id &&
                                selectedSubcategoryId === subcategory._id
                              }
                              onChange={() => {
                                const newSelectedSubcategoryId =
                                  selectedSubcategoryId === subcategory._id
                                    ? null
                                    : subcategory._id;
                                setSubcategoryId(newSelectedSubcategoryId);
                                setSelectedSubcategoryId(
                                  newSelectedSubcategoryId
                                );
                                setCategoryId(category._id);
                                setSelectedCategoryId(category._id);
                              }}
                            />
                            <span>{subcategory.name}</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
          </ul>
        </div>

        {/* Brands */}
        <div className="brands">
          <h2
            onClick={() => setShowBrands(!showBrands)}
            className="dropdown-header"
          >
            Brands <span>{showBrands ? "▼" : "►"}</span>
          </h2>
          <ul className={`dropdown-list ${showBrands ? "expanded" : ""}`}>
            {showBrands &&
              (Array.isArray(brandsResponse?.data) &&
              brandsResponse.data.length > 0 ? (
                brandsResponse.data.map((brand) => (
                  <li key={brand._id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedBrandId === brand._id}
                        onChange={() => {
                          const newSelectedBrandId =
                            selectedBrandId === brand._id ? null : brand._id;
                          setBrandId(newSelectedBrandId);
                          setSelectedBrandId(newSelectedBrandId);
                          if (newSelectedBrandId === null) {
                            setCategoryId(null);
                            setSubcategoryId(null);
                            setSelectedCategoryId(null);
                            setSelectedSubcategoryId(null);
                          }
                        }}
                      />
                      <span>{brand.name}</span>
                    </label>
                  </li>
                ))
              ) : (
                <li>No brands available</li>
              ))}
          </ul>
        </div>
        <Button
          variant="contained"
          color="error"
          onClick={clearFilters}
          fullWidth
        >
          Clear Filters
        </Button>
      </div>

      {/* Right Content for Products */}
      <div className="right-content">
        {loadingProducts ? (
          <div className="loading-container">
            <Loader />
          </div>
        ) : (
          <ul className="product-grid">
            {products.length > 0 ? (
              products.map((product) => (
                <ProductCard key={product._id} data={product} />
              ))
            ) : (
              <span>No products available</span>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ProductSearch;
