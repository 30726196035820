import React from 'react'
import Account from '../components/Account/Account'
import About from '../components/About/About'
import VerifyEmail from '../components/EmailVerify/EmailVerify'
import TokenVerify from '../components/EmailVerify/TokenVerify'

const TOkenVerifyPage = () => {
  return (
    <div>
        <TokenVerify />
    </div>
  )
}

export default TOkenVerifyPage