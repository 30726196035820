import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSendVerificationTokenMutation } from '../../redux/Apis/Auth';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const EmailSent = () => {
    const navigate = useNavigate();

    return (
        <Container
            maxWidth="sm"
            sx={{
                my: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '50vh'
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                    maxWidth: 400,
                    width: '100%'
                }}
            >
                <CheckCircleIcon sx={{ fontSize: 60, color: 'success.main', mb: 2 }} />
                
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                        fontWeight: 'bold',
                        color: 'text.primary',
                        fontSize: '1.5rem'
                    }}
                >
                    Check Your Email!
                </Typography>
                
                <Typography
                    variant="body1"
                    sx={{
                        color: 'text.secondary',
                        mb: 3
                    }}
                >
                    We sent a confirmation link to your registered email address. Please click on the link to verify your email and complete the login process.
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/')}
                    sx={{
                        mt: 2,
                        px: 4,
                        py: 1,
                        borderRadius: 1
                    }}
                >
                    Go to Home
                </Button>
            </Box>
        </Container>
    );
};

export default EmailSent;