// import ForgotPasswordForm from "../components/Auth/ForgotPassword";
import VerifyUser from "../components/Auth/VerifyUser";

const VerifyUserPage = () => {
  return (
    <div>
      <VerifyUser />
    </div>
  );
};

export default VerifyUserPage;
