import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./App.routes";
import "./App.css";
import { useGetLOVQuery } from "../redux/Apis/General";
import NewsletterModal from "../components/Newsletter/NewsletterModal";

const App = () => {
  const lang = localStorage.getItem("language");
  const htmlTag = document.documentElement;

  if (lang === "ar" || lang === "kr") {
    htmlTag.setAttribute("dir", "rtl");
  } else {
    htmlTag.setAttribute("dir", "ltr");
  }

  const {} = useGetLOVQuery({}, { refetchOnFocus: true });

  return (
    <>
      <ToastContainer />
      <NewsletterModal /> {/* Add the NewsletterModal component here */}
      <AppRoutes />
    </>
  );
};

export default App;
