import ChangePasswordForm from "../components/Auth/ChangePassword";
// import VerifyCode from "../components/Auth/VerifyCode";

const ResrePasswordPage = () => {
  return (
    <div>
      <ChangePasswordForm />
    </div>
  );
};

export default ResrePasswordPage;
