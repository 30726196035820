import React from "react";
import "./Footer.css";
import Logo from "../../shared/images/new-logo.png";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Footer = () => {
  const nav = useNavigate();
  const { t } = useTranslation();

  const selectedLanguage = useSelector((state) => state.language);
  const isRTL = selectedLanguage === "ar" || selectedLanguage === "kr";

  return (
    <footer className={`footer ${isRTL ? "rtl" : ""}`}>
      <div className="text-center text-md-start p-4 p-md-5">
        <div className="footer-wrap">
          <div className="company">
            <h3 style={{ fontWeight: "normal", marginBottom: "20px" }}>
              {t("Information")}
            </h3>
            <p>
              <a href="#" onClick={() => nav("/return")}>
                {t("ShippingReturn")}
              </a>
            </p>
            <p>
              <a href="#" onClick={() => nav("/terms")}>
                {t("Terms")}
              </a>
            </p>
            <p>
              <a href="#" onClick={() => nav("/privacy")}>
                {t("Privacy Policy")}
              </a>
            </p>
          </div>
          <div className="company">
            <h3 style={{ fontWeight: "normal", marginBottom: "20px" }}>
              {t("HelpSupport")}
            </h3>
            <p>
              <a href="#" onClick={() => nav("/contact")}>
                {t("about.contactUs.heading")}
              </a>
            </p>
          </div>
          <div className="about">
            <h2 style={{ marginBottom: "20px", fontWeight: "bold" }}>
              {t("NewsLetterSignUp")}
            </h2>
            <p style={{ letterSpacing: "2px" }}>
              {t("about.newsLetter.heading")}
            </p>
            <div className="custom-subscribe-container">
              <input
                className="custom-subscribe-input"
                type="text"
                placeholder={t("Email Address")}
              />
              <button
                className="custom-subscribe-button"
                style={{
                  borderRadius: isRTL ? "0 25px 25px 0" : "25px 0 0 25px",
                  borderLeft: isRTL ? "none" : "2px solid black",
                  borderRight: isRTL ? "2px solid black" : "none",
                }}
                // onClick={() => navigate(`/products?subscribe=${subscribeTerm}`)}
              >
                {t("Subscribe")}
              </button>
            </div>
            <div className="social-media">
              <p style={{ fontSize: "18px", marginBottom: "0px" }}>
                {t("Follow Us")}
              </p>
              <div>
                <a href="https://www.facebook.com/profile.php?id=61572221131942" target={"_blank"} className="me-2 text-reset">
                  <FacebookIcon />
                </a>
                <a href="https://www.instagram.com/minasatkom.iraq/" target={"_blank"} className="me-2 text-reset">
                  <InstagramIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
