import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import "./Navbar.css";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import Logo from "../../shared/images/new-logo.png";
import MenuTransitions from "./MenuTransitions";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ModalSearchBox from "./ModalSearchBox";
import LocalPhone from "@mui/icons-material/LocalPhone";
import MegaMenu from "./MegaMenu";
import { useDispatch, useSelector } from "react-redux";
import { openCartModal, closeCartModal } from "../../redux/Slices/CartModal";
import { useTranslation } from "react-i18next";
import { setLanguage } from "../../redux/Slices/Language"; // Import setLanguage action
import { Select, MenuItem, IconButton } from "@mui/material";
import { Globe } from "lucide-react";
import Flag from "react-world-flags";
import { useGetAllHeaderMessagesQuery } from "../../redux/Apis/HeaderSettings";
import MobileMegaMenu from "./MobileMegaMenu";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import axios from "axios";
import cartIcon from "../../shared/images/cartIcon.svg";
// Lazy load CartModalRight
const CartModalRight = lazy(() => import("../Cart/CartRight"));

const Navbar = () => {
  const navbarCollapseRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isCartModalOpen = useSelector((state) => state.modal.isCartModalOpen);
  const { t } = useTranslation();
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const currentLanguage = useSelector((state) => state.language);
  // const { data, error, isLoading, refetch } = useGetAllHeaderMessagesQuery();
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const collapseRef = useRef(null);

  const selectedLanguage = useSelector((state) => state.language);
  const isRTL = selectedLanguage === "ar" || selectedLanguage === "kr";

  const fetchHeaderMessages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_NEW}/header-messages/`
      );
      const data = response?.data?.data;
      const filteredMessages = data
        ? data.filter((message) => message.show === true)
        : [];
      setVisibleMessages(filteredMessages);
    } catch (error) {
      console.error("Error fetching header messages:", error);
    }
  };

  // Function to check screen width and update the state
  const updateMenuVisibility = () => {
    const width = window.innerWidth;
    setIsMobileMenu(width <= 991); // Set true if width is 991px or less
  };

  // Add event listener for resize and set initial state
  useEffect(() => {
    updateMenuVisibility(); // Check on component mount
    window.addEventListener("resize", updateMenuVisibility); // Update on window resize

    return () => {
      window.removeEventListener("resize", updateMenuVisibility); // Cleanup listener on unmount
    };
  }, []);

  useEffect(() => {
    fetchHeaderMessages(); // Fetch messages on component mount

    const interval = setInterval(() => {
      fetchHeaderMessages(); // Refetch messages every 30 minutes
    }, 3000000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (visibleMessages.length > 0) {
      const interval = setInterval(() => {
        setCurrentMessageIndex(
          (prevIndex) => (prevIndex + 1) % visibleMessages.length
        );
      }, 3000); // Change message every 3 seconds

      return () => clearInterval(interval);
    }
  }, [visibleMessages]);

  const handleNavLinkClick = () => {
    if (collapseRef.current) {
      collapseRef.current.classList.remove("show");
    }
  };

  // useEffect(() => {
  //   if (isLoading) {
  //     // Log when the query is loading
  //   } else if (data) {
  //     const filteredMessages = Array.isArray(data.data)
  //       ? data.data.filter((message) => message.show === true)
  //       : [];
  //     setVisibleMessages(filteredMessages);
  //   }
  // }, [data, isLoading]);

  const handleLanguageChange = (lang) => {
    dispatch(setLanguage(lang)); // Dispatch Redux action to change language
  };

  const cartItems = useSelector((state) => state.cartSlice.items);
  const totalItemsInCart = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  const handleShowSearch = () => {
    setShowSearch(!showSearch);
  };

  const handleOutsideClick = (event) => {
    if (
      navbarCollapseRef.current &&
      !navbarCollapseRef.current.contains(event.target)
    ) {
      const isNavbarExpanded = navbarCollapseRef.current.querySelector(
        ".navbar-collapse.show"
      );
      if (isNavbarExpanded) {
        const navbarToggle = document.querySelector(".navbar-toggler");
        if (navbarToggle) {
          navbarToggle.click(); // Trigger the collapse
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [navbarCollapseRef]);

  const isCheckoutRoute = location.pathname === "/auth/checkout";

  const handleCartClick = () => {
    if (!isCheckoutRoute) {
      dispatch(openCartModal());
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  const handleSelect = (language) => {
    handleLanguageChange(language);
    closeDropdown();
  };

  const toggleLanguage = () => {
    if (currentLanguage === "swe") {
      handleLanguageChange("en");
    } else {
      handleLanguageChange("swe");
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <div>
        {isCartModalOpen && (
          <Suspense fallback={<div>Loading...</div>}>
            <CartModalRight
              isOpen={isCartModalOpen}
              onRequestClose={() => dispatch(closeCartModal())}
            />
          </Suspense>
        )}

        <div className="page-top">
          <p className="message">
            {(visibleMessages.length > 0 &&
              selectedLanguage === "en" &&
              visibleMessages[currentMessageIndex].message_en) ||
              (selectedLanguage === "ar" &&
                visibleMessages[currentMessageIndex]?.message_ar) ||
              (selectedLanguage === "kr" &&
                visibleMessages[currentMessageIndex]?.message_ku)}
          </p>
        </div>

        <nav className="navbar navbar-expand-lg " ref={navbarCollapseRef}>
          <div className="container-fluid">
            <div>
              <Link
                className="navbar-brand"
                to="/"
                style={{ marginRight: isRTL ? "35px" : "" }}
              >
                <img src={Logo} className="logo" alt="Logo" />
              </Link>
            </div>

            <ul className="navbar-nav large-items">
              <div className="custom-search-container">
                <input
                  className="custom-search-input"
                  type="text"
                  placeholder="search for products"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`/products?search=${searchTerm}`);
                    }
                  }}
                />
                <button
                  className="custom-search-button"
                  onClick={() => navigate(`/products?search=${searchTerm}`)}
                  style={{
                    borderRadius: isRTL ? "0 25px 25px 0" : "25px 0 0 25px",
                    borderLeft: isRTL ? "none" : "2px solid black",
                    borderRight: isRTL ? "2px solid black" : "none",
                  }}
                >
                  <SearchIcon className="" style={{ fontSize: "1.9re" }} />
                </button>
              </div>
            </ul>
            <div>
              <ul className="navbar-nav mb-lg-0 p-0">
                <div className="icons-container">
                  <div className="small-items mt-1">
                    <SearchIcon
                      className=""
                      style={{ fontSize: "1.9rem", marginRight: "15px" }}
                      onClick={() => handleShowSearch()}
                    />
                  </div>

                  <MenuTransitions />

                  <div
                    className="cart-icon-container nav-icon-container "
                    onClick={handleCartClick}
                  >
                    <img
                      src={cartIcon}
                      height="35px"
                      // width="1.6rem"
                      className="nav-icons mt-1"
                      alt=""
                    />
                    {/* <ShoppingBasketOutlinedIcon
                      style={{ fontSize: "1.6rem" }}
                      // fontSize="medium"
                      className="nav-icons mt-1"
                    /> */}
                    <div className="cart-counter">{totalItemsInCart}</div>
                  </div>
                  <div className="cart-icon-container nav-icon-container ">
                    <LanguageSelector
                      handleLanguageChange={handleLanguageChange}
                      currentLanguage={
                        currentLanguage ||
                        localStorage.getItem("language") ||
                        "en"
                      }
                    />
                  </div>
                </div>
              </ul>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
        <nav className="navbar navbar-expand-lg py-0">
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
            ref={collapseRef}
          >
            <ul
              dir={isRTL ? "rtl" : ""}
              style={{ marginLeft: `${isRTL ? "25px" : ""}` }}
              className="navbar-nav mb-2 mb-lg-0"
            >
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link active"
                  aria-current="page"
                  onClick={handleNavLinkClick}
                >
                  {t("Home")}
                </Link>
              </li>
              <div className="nav-link-mega">
                {isMobileMenu ? <MobileMegaMenu /> : <MegaMenu />}
              </div>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/brands"
                  onClick={handleNavLinkClick}
                >
                  {t("Brands")}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/about"
                  onClick={handleNavLinkClick}
                >
                  {t("About")}
                </Link>
              </li> */}

              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/contact"
                  onClick={handleNavLinkClick}
                >
                  {t("Contact")}
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <ModalSearchBox showSearch={showSearch} />
    </>
  );
};

export default Navbar;
