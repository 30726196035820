import React from "react";
import { Helmet } from "react-helmet";
import TermsAndConditions from "../components/Term/Term";

const TermPage = () => {
  return (
    <div>
      {/* React Helmet for SEO */}
      <Helmet>
        <title>Minasatkom | Allmänna Villkor</title>
        <meta
          name="description"
          content="اقرأ شروطنا وأحكامنا لفهم القواعد والإرشادات عند التسوق في Minasatkom."
        />
        <meta
          name="keywords"
          content="الشروط والأحكام، Minasatkom، القواعد، الإرشادات، شروط الاستخدام، الشراء"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="**Minasatkom | الشروط والأحكام**" />
        <meta
          property="og:description"
          content="اطلع على شروطنا وأحكامنا لمعرفة المزيد عن قواعدنا وإرشاداتنا أثناء الشراء."
        />
        <meta property="og:image" content="/path/to/terms-og-image.jpg" />
        <meta property="og:url" content="https://minasatkom.com" />
        <meta property="og:type" content="article" />
      </Helmet>

      {/* Terms and Conditions Content */}
      <TermsAndConditions />
    </div>
  );
};

export default TermPage;
