import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSendVerificationTokenMutation } from '../../redux/Apis/Auth';

const VerifyEmail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState(location.state?.email || ''); // Get email from location state
    const [verification, { isSuccess, isLoading, error }] = useSendVerificationTokenMutation();

    const handleResendEmail = () => {
        // Function to handle resend email
        if (email) {
            verification({email: email});
            navigate('/email-sent')
            // API call to resend verification email goes here
            toast.success("Verification email sent successfully!");
            // Optionally navigate somewhere or provide feedback
        } else {
            toast.error("Please enter your email.");
        }
    };

    return (
        <Container
            maxWidth="sm"
            sx={{
                my: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '40vh'
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'white',
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                    maxWidth: 400,
                    width: '100%'
                }}
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                        fontWeight: 'bold',
                        color: 'text.primary',
                        fontSize: '1.5rem'
                    }}
                >
                    We noticed that your email is not verified
                </Typography>
                <Typography
                    variant="body1"
                    color="textSecondary"
                    gutterBottom
                    disabled={isSuccess}
                    sx={{
                        fontSize: '0.9rem',
                        marginBottom: 2
                    }}
                >
                    Please enter your email below to receive a verification link.
                </Typography>
            

                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    disabled={isSuccess}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 2,
                        }
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSuccess}
                    onClick={handleResendEmail}
                    sx={{
                        mt: 2,
                        paddingY: 1,
                        borderRadius: 2,
                        fontWeight: 'bold',
                        fontSize: '0.9rem',
                        textTransform: 'none',
                        backgroundColor: '#FF6A6A',
                        '&:hover': {
                            backgroundColor: '#FF5252'
                        }
                    }}
                >
                    Send Verification Email
                </Button>
            </Box>
        </Container>
    );
};

export default VerifyEmail;
