import React from "react";
import { Outlet, useLocation } from "react-router-dom"; // Import useLocation
import { Box } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import CookieConsent from "../CookiesConsent/CookiesConsent";
import { useSelector, useDispatch } from "react-redux";
import { closeCartModal } from "../../redux/Slices/CartModal/index"; // Adjust the path as needed
import CartModal from "../Cart/CartRight"; // Adjust the path as needed
import "./styles.css";

const Wrapper = () => {
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current route location
  const isCartModalOpen = useSelector((state) => state.modal.isCartModalOpen);

  const handleClose = () => {
    dispatch(closeCartModal());
  };

  // Check if the current route is the checkout route
  const isCheckoutRoute = location.pathname === "/auth/checkout"; // Adjust the path as needed

  return (
    <>
      <Navbar />
      <Box class="wrapper-container">
        <CookieConsent />
        <Box>
          <Outlet />
        </Box>
        {/* Conditionally render the cart modal only if not on the checkout route and if it's open */}
        {!isCheckoutRoute && isCartModalOpen && (
          <CartModal onClose={handleClose} />
        )}
      </Box>
      <Footer />
    </>
  );
};

export default Wrapper;
