import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ThemeProvider } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../../redux/Apis/Auth";
import { StandardInputTheme } from "./StandardInputTheme";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./style.css";

const ChangePasswordForm = () => {
  const { t } = useTranslation(); // Hook to access translations
  const { state } = useLocation();
  const email = state?.email;
  const selectedLanguage = useSelector((state) => state.language);
  const isRTL = selectedLanguage === "ar" || selectedLanguage === "kr";

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const [resetPassword, { isSuccess, isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate("/auth/login"); // Redirect to login after success
    }
  }, [isSuccess, navigate]);

  const password = watch("new_password", "");

  const onSubmit = (formData) => {
    const payload = {
      email, // from state
      otp: formData.otp, // input field
      new_password: formData.new_password, // input field
    };
    resetPassword(payload); // Send the payload
  };

  return (
    <ThemeProvider theme={StandardInputTheme}>
      <div
        className={`login-form-container shadow ${isRTL ? "rtl" : ""}`}
        style={{ direction: isRTL ? "rtl" : "ltr" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="loginpage_heading">{t("Change Password")}</h2>

          {/* OTP Field */}
          <div className="input-group">
            <Controller
              name="otp"
              control={control}
              rules={{
                required: t("OTP is required"),
                minLength: {
                  value: 6,
                  message: t("OTP must be 6 digits"),
                },
                maxLength: {
                  value: 6,
                  message: t("OTP must be 6 digits"),
                },
              }}
              render={({ field }) => (
                <FormControl fullWidth variant="standard" error={!!errors.otp}>
                  <InputLabel
                    htmlFor="otp-input"
                    style={{
                      transformOrigin: isRTL ? "right" : "left",
                      left: isRTL ? "inherit" : 0,
                      textAlign: isRTL ? "right" : "left",
                    }}
                  >
                    {t("OTP")}
                  </InputLabel>
                  <Input
                    id="otp-input"
                    type="text"
                    {...field}
                    style={{
                      textAlign: isRTL ? "right" : "left",
                    }}
                  />
                  {errors.otp && (
                    <FormHelperText
                      error
                      style={{
                        textAlign: isRTL ? "right" : "left",
                        direction: isRTL ? "rtl" : "ltr",
                      }}
                    >
                      {errors.otp.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>

          {/* Password Field */}
          <div className="input-group">
            <Controller
              name="new_password"
              control={control}
              rules={{
                required: t("Password is required"),
                minLength: {
                  value: 8,
                  message: t("Password must be at least 8 characters long"),
                },
              }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!errors.new_password}
                >
                  <InputLabel
                    htmlFor="new-password-input"
                    style={{
                      transformOrigin: isRTL ? "right" : "left",
                      left: isRTL ? "inherit" : 0,
                      textAlign: isRTL ? "right" : "left",
                    }}
                  >
                    {t("New Password")}
                  </InputLabel>
                  <Input
                    id="new-password-input"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    {...field}
                    style={{
                      textAlign: isRTL ? "right" : "left",
                    }}
                  />
                  {errors.new_password && (
                    <FormHelperText
                      error
                      style={{
                        textAlign: isRTL ? "right" : "left",
                        direction: isRTL ? "rtl" : "ltr",
                      }}
                    >
                      {errors.new_password.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="btn btn-success w-100 mt-3"
            disabled={isLoading}
          >
            {t("Update Password")}
          </button>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default ChangePasswordForm;
