import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = `${process.env.REACT_APP_BASE_URL}/carousal/`;

export const carousalApiService = createApi({
    reducerPath: 'carousalApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const { token } = getState().authSlice; // Destructuring 'token' from authSlice state
            if (token) {
                headers.set('Authorization', `Bearer ${token}`); // Setting the Authorization header
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCarousal: builder.query({
            query: () => ({
                url: 'get',
                method: 'GET',
            }),
        }),

       
        
    }),
});

export const {
    useGetCarousalQuery,
} = carousalApiService;